import { useState } from 'react';
import {
    TextField, Grid, Button, Typography, FormHelperText, DialogContent, Dialog, DialogActions,
    DialogTitle
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { confirmDeleteAccount, deleteAccount } from '../../store/actions/sessionAction';
import { useNavigate } from "react-router-dom";
import backImage from '../../assets/backImageLogin.jpg'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import { customStyles, fontFamily } from '../../helpers/customStyles';
import DisabledByDefaultRoundedIcon from "@mui/icons-material/DisabledByDefaultRounded";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

export default function AccountDeletion() {

    const [state, setState] = useState({
        userName: "",
        userNameError: false,
        otp: "",
        otpError: "",
        password: "",
        passwordError: "",
        validationError: false,
        otpView: false,
        dialogOpen: false,
        errorMessage: 'Invalid username or password'
    });
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClickShowPassword = () => {
        setShowPassword((show) => !show)
    };

    const handleLogin = () => {
        let { userName, password } = state;

        let isError = false;
        if (userName === '' || userName === null || userName === undefined) {
            setState((prevState) => ({ ...prevState, userNameError: true }));
            isError = true;
        }

        if (password === '' || password === null || password === undefined) {
            setState((prevState) => ({ ...prevState, passwordError: true }));
            isError = true;
        }


        if (isError === false) {
            let data = { user_name: userName, password: password };
            setState((prevState) => ({ ...prevState, otpView: true }))
            dispatch(deleteAccount(data));
        }
    };

    const sendOtp = () => {
        setState((prevState) => ({ ...prevState, dialogOpen: true }));
    }

    const onEnterAction = (event) => {
        if (event.key === 'Enter') {
            handleLogin()
        }
    }

    const otpValidation = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        if (onlyNums.length < 10) {
            setState((prevState) => ({ ...prevState, otp: onlyNums }))
        } else if (onlyNums.length === 10) {
            const number = onlyNums.replace(
                /(\d{3})(\d{3})(\d{4})/,
                '($1) $2-$3'
            );
            setState((prevState) => ({ ...prevState, otp: number }))
        }
    }

    const handleDialogClose = () => {
        setState((prevState) => ({ ...prevState, dialogOpen: false }))
    }

    const styles = {
        containerBackground: {
            backgroundImage: `url(${backImage})`,
            height: '100vh',
            backgroundSize: 'cover',
            filter: 'blur(0px)',
        },
        backgroundContainer: {
            borderRadius: '4%',
            background: 'rgba( 255, 255, 255, 0.25 )',
            boxshadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
            backdropFilter: 'blur( 4px )',
            border: '1px solid rgba( 255, 255, 255, 0.18 )',
        },
        loginHeader: {
            color: 'white',
            textAlign: 'center',
            marginTop: '5%',
            fontWeight: 'bold',
            fontSize: '16px'
        },
        loginButton: {
            padding: "8px 70px",
            borderRadius: '16px',
            fontWeight: 'bold',
            background: 'linear-gradient(to right, #183883, #E62D38)'
        },
        loginButtonContainer: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: 20
        },
        passwordErrorMessage: {
            color: '#fff',
            backgroundColor: 'red',
            padding: '0px 10px 0px 10px'
        },
        forgotPasswordErrorText: {
            color: 'white',
            textTransform: 'capitalize',
            fontWeight: 'bold'
        },
        dialogText: {
            fontFamily: fontFamily.regularFont,
        fontSize: "16px",
        fontWeight: 'normal',
        textAlign: 'justify',
        margin: 3
    }
    }

    const confirmDeletion = async () => {
        let obj = {};
        obj.verification_code = state.otp;
        dispatch(confirmDeleteAccount(obj, navigate, setState))
    }

    return (
        <Grid onKeyDown={(e) => onEnterAction(e)} container justifyContent='center' alignItems='center' style={styles.containerBackground}>
            <Grid item xs={10} sm={5} md={4} lg={3} xl={3}>
                <Grid container>
                    <Grid item xs={12} style={styles.backgroundContainer}>
                        <Typography style={styles.loginHeader}>REQUEST ACCOUNT DELETION</Typography>
                        <Grid container rowGap={3} justifyContent={'center'} style={{ marginTop: '10%', }}>
                            {!state.otpView ?
                                <Grid container justifyContent={'center'}>
                                    <Grid item xs={8} style={{ marginBottom: '5%' }}>
                                    <Typography style={{ color: '#fff' }}>User Name</Typography>
                                        <TextField
                                            inputProps={{
                                                style: {
                                                    color: '#fff',
                                                }
                                            }} placeholder="Enter the Username" color='secondary' focused autoComplete='off' variant='outlined' fullWidth  value={state.userName} size={'medium'} onChange={(e) => setState({ ...state, userName: e.target.value.trim(), userNameError: false, validationError: false })} />
                                        {state.userNameError &&
                                            <FormHelperText style={{ color: "#fff", marginLeft: "5px" }}>Please enter the username</FormHelperText>
                                        }
                                    </Grid>
                                    <Grid item xs={8}>
                                        <InputLabel style={{ color: '#fff' }}>Password</InputLabel>
                                        <OutlinedInput
                                            type={showPassword ? 'text' : 'password'}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => handleClickShowPassword()}
                                                        edge="end"
                                                    >
                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                            placeholder=' Password'
                                            sx={{ color: '#fff' }}
                                            fullWidth
                                            color='secondary' variant='outlined' value={state.password} size={'medium'} onChange={(e) => setState({ ...state, password: e.target.value.trim(), passwordError: false, validationError: false })}
                                        />
                                        {/* </FormControl> */}
                                        {state.passwordError === true &&
                                            <FormHelperText style={{ color: "#fff", marginLeft: "5px" }}>Please enter the password</FormHelperText>
                                        }
                                    </Grid>
                                </Grid> :
                                <Grid item xs={8} style={{ marginBottom: '5%' }}>
                                    <TextField
                                        inputProps={{
                                            maxLength: 6,
                                            style: {
                                                color: '#fff',
                                            }
                                        }} placeholder="Enter otp" color='secondary' autoComplete='off' variant='outlined' fullWidth focused value={state.otp} label="OTP" size={'medium'} onChange={(e) => otpValidation(e)} />
                                    {state.userNameError &&
                                        <FormHelperText style={{ color: "#fff", marginLeft: "5px" }}>Please enter the otp</FormHelperText>
                                    }
                                </Grid>
                            }
                            {!state.otpView ?
                                <Grid item xs={8} style={styles.loginButtonContainer}>
                                    <Button style={styles.loginButton} type="submit" variant='contained' onClick={() => handleLogin()}>Send Otp</Button>
                                </Grid>
                                :
                                <Grid item xs={9} style={styles.loginButtonContainer}>
                                    <Button style={styles.loginButton} type="submit" variant='contained' onClick={() => sendOtp()}>Verify Otp</Button>
                                </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ position: 'absolute', bottom: 0, justifyContent: 'flex-end', backgroundColor: '#0A3058' }}>
                <Grid item xs={12} sm={6} md={4} lg={3} sx={{ p: 1 }}>
                    <Grid container justifyContent={'space-around'}>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/privacy-policy`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>Privacy policy</a></Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/terms-and-conditions`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>Terms of Service</a></Typography>
                        </Grid>
                        <Grid item>
                            <Typography style={{ color: '#ffff' }}><a target='_blank' rel="noreferrer" href={`/about`} style={{ textDecorationLine: 'underline', cursor: 'pointer', color: '#fff' }}>About</a></Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Dialog
                open={state.dialogOpen}
                onClose={handleDialogClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle style={{ display: 'flex' }} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography style={customStyles.boldText}>Account Deletion Confirmation</Typography>
                    <IconButton onClick={() => handleDialogClose()} style={{ color: '#000' }}>
                        <DisabledByDefaultRoundedIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Grid container flexDirection={'row'}>
                        <Grid item xs={12}>
                            <Typography style={styles.dialogText}><span style={{ fontWeight: 'bolder' }}>Are you sure you want to delete your account? </span>
                            </Typography>
                            <Typography style={styles.dialogText}>This action is permanent, and all your data will be lost.</Typography>
                            <Typography style={styles.dialogText}><WarningAmberRoundedIcon color='error' style={{ margin: 5, marginBottom: -5 }} />Once deleted, you will no longer have access to your account or any associated services.</Typography>
                        </Grid>
                        {/* <Grid item xs={12} style={{ display: 'flex', }}>
                            <Typography></Typography>
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus variant='contained' color='error' style={customStyles.cancelButtonText} onClick={handleDialogClose}>Cancel</Button>
                    <Button autoFocus variant="contained" color="primary" style={customStyles.buttonText} sx={{ '&.MuiButton-root:hover': { bgcolor: "#2F7FFF" } }} onClick={confirmDeletion} >Delete</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
