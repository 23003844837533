// Importing the API endpoint constant
import { API_END_POINT } from "../helpers/constants";

// Function to parse and handle the API response
function parseResponse(response) {
    return response.json().then((json) => {
        if (json.status === "success" || json.status === "failed") {
            return json;
        }
        return Promise.reject(json);
    });
}

// API utility object with methods for different HTTP request types
const accountApi = {
    // Method for making GET requests
    async get(url) {
        try {
            const token = localStorage.getItem('accessToken');
            // console.log('token in API', token)
            const bearer = "Bearer " + token;
            const response = await fetch(`${API_END_POINT}${url}`, {
                method: "GET",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: bearer,
                }),
            });
            return parseResponse(response);

        } catch (err) {
            console.log(err);
        }
    },

    // Method for making POST requests
    async post(url, data) {
        try {
            let token = localStorage.getItem('accessToken');
            const bearer = "Bearer " + token;
            const body = JSON.stringify(data);
            const response = await fetch(`${API_END_POINT}${url}`, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: bearer,
                }),
                body,
            });
            return parseResponse(response);

        } catch (err) {
            console.log("err");
            console.log(JSON.stringify(err));

        }
    },
    // Method for making PUT requests
    async put(url, data) {
        try {
            const token = localStorage.getItem('accessToken');
            const bearer = "Bearer " + token;
            const body = JSON.stringify(data);
            const response = await fetch(`${API_END_POINT}${url}`, {
                method: "PUT",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: bearer,
                }),
                body,
            });
            return parseResponse(response);
        } catch (err) {
            console.log(err);
        }
    },
    // Method for making PATCH requests
    async patch(url, data) {
        try {
            const token = localStorage.getItem('accessToken');
            const bearer = "Bearer " + token;
            const body = JSON.stringify(data);
            const response = await fetch(`${API_END_POINT}${url}`, {
                method: "PATCH",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: bearer,
                }),
                body,
            });
            return parseResponse(response);
        } catch (err) {
            console.log(err);
        }
    },
    async postR(url, data) {
        try {
            let token = localStorage.getItem('dAccessToken');
            const bearer = "Bearer " + token;
            const body = JSON.stringify(data);
            const response = await fetch(`${API_END_POINT}${url}`, {
                method: "POST",
                headers: new Headers({
                    "Content-Type": "application/json",
                    Authorization: bearer,
                }),
                body,
            });
            return parseResponse(response);

        } catch (err) {
            console.log("err");
            console.log(JSON.stringify(err));

        }
    },
};

// Exporting the API utility object for use in other parts of the application
export default accountApi;
