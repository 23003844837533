import API from '../../api/accountApi';
import { displayAlert } from '../reducer/alertReducer';
import { login } from '../reducer/sessionReducer';
import { sideBar } from '../../helpers/sideBarMenu';

/**
 * User login 
 * @function
 * @param {Object} data - The login data containing username and password
 * @param {Function} navigate - The function to navigate to different routes
 * @param {Object} state - The current state of the component
 * @param {Function} setState - The function to update the state of the component
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function userLogin(data, navigate, state, setState) {
    return async (dispatch) => {
        API.post('user/login', data).then((response) => {
            if (response.status === 'success') {
                localStorage.setItem("accessToken", response.token.accessToken);
                localStorage.setItem("refreshToken", response.token.refreshToken);
                if (response.token.accessToken) {
                    let findPath = sideBar.find(item => item.screen === response.payload.role.permission[0]['screen']);
                    dispatch(login(response));
                    navigate(findPath.path);
                } else {
                    navigate('/');
                }
            }
            else {
                setState({ ...state, validationError: true, errorMessage: response.message })
                // dispatch(displayAlert({ "openAlert": true, alertSeverity: "error", alertMessage: "Invalid username or password" }));
            }
        });
    }
}

/**
 * Refresh user tokens
 * @function
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function refreshAction() {
    return async (dispatch) => {
        API.post('user/refresh', {}).then((response) => {
            if (response.status === 'success') {
                localStorage.setItem("accessToken", response.token.accessToken);
                localStorage.setItem("refreshToken", response.token.refreshToken);
                if (response.token.accessToken) {
                    dispatch(login(response));
                }
            } else {
                localStorage.clear();
                window.location.replace('/');
            }
        });
    }
}

/**
 * Send verification code 
 * @function
 * @param {Object} data - The data containing user information for password reset
 * @param {Function} navigate - The function to navigate to different routes
 * @returns {Function} dispatch - The dispatch function to trigger actions
 */
export function verificationCode(data, navigate) {
    return async (dispatch) => {
        API.post('user/forgot-password', data).then((response) => {
            if (response.status === "success") {
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "success", alertMessage: 'Your verification code has been sent to Email' }));
                navigate("/confirm-password", { state: data });
            }
            else {
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "error", alertMessage: response.message, }));
            }
        })
    }
}

export function deleteAccount(data) {
    return async (dispatch) => {
        API.post('user/delete-account', data).then((response) => {
            if (response.status === 'success') {
                localStorage.setItem("dAccessToken", response.token.accessToken);
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "success", alertMessage: "OTP sent to email" }));
            }
        });
    }
}

export function confirmDeleteAccount(data, navigate, setState) {
    return async (dispatch) => {
        API.postR('user/confirm-deletion', data).then((response) => {
            if (response.status === 'success') {
                setState((prevState) => ({ ...prevState, dialogOpen: false }));
                dispatch(displayAlert({ "openAlert": true, alertSeverity: "success", alertMessage: "Account deleted successfully" }));
                navigate('/')
            }
        });
    }
}